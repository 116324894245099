.form {
    background: #fff;
    box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
    border-radius: 5px;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    left: 0;
    right: 0;
    position: absolute;
    border-top: 5px solid #0e3721;
}
.name {
    background-color: #ffffff;
    color: white;
  }
  .email {
    background-color: #ffffff;
    height: 2em;
  }
  .message {
    background-color: #ffffff;
    overflow: hidden;
    height: 10rem;
  }
  .formEntry {
    display: block;
    margin: 30px auto;
    min-width: 300px;
    padding: 10px;
    border-radius: 2px;
    border: none;
    transition: all 0.5s ease 0s;
}
.submit {
    width: 200px;
    color: white;
    background-color: #645abb;
    font-size: 20px;
  }
  .title{
    display: block;
    font-family: sans-serif;
    margin: 10px auto 5px;
    width: 300px;
  }
  .secondaryTitle{
    color: rgb(255, 253, 253);
    background-color: #0f0f0f;
  } 
  .pageTitle{
    font-size: 2em;
    font-weight: bold;
  }